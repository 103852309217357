<template>
    <section id="payments">
        <h1 id="page-title">
            <b-row class="align-items-center">
                <b-col>{{ $t('payments') }}</b-col>
                <b-col class="d-md-none d-block" cols="auto">
                    <button class="btn-themed" @click="addPayment">
                        <PlusSvg class="svg-white svg-icon-sm"/>
                    </button>
                </b-col>
            </b-row>
        </h1>
        <b-row class="row-wide">
            <b-col md="auto">
                <div class="left-sideblock">
                    <button class="btn-themed w-100 d-md-block d-none mb-24" @click="addPayment">{{ $t('add_payment') }}</button>
                    <div class="spacecard mt-md-3 mt-0 md">
                        <h3 class="subform-h">{{ $t('report') }}</h3>
                        <b-row>
                            <b-col md="12" cols="6">
                                <div class="mb-md-3 mb-0">
                                    <b-form-datepicker v-model="from"
                                                       :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                       class="form-control form-control-white padded position-relative"
                                                       :locale="lang" hide-header>
                                        <template v-slot:button-content>
                                            <CalendarSvg class="calendar-icon"/>
                                        </template>
                                    </b-form-datepicker>
                                </div>
                            </b-col>
                            <b-col md="12" cols="6">
                                <div class="">
                                    <b-form-datepicker v-model="to"
                                                       :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                       class="form-control form-control-white padded position-relative"
                                                       :locale="lang" hide-header>
                                        <template v-slot:button-content>
                                            <CalendarSvg class="calendar-icon"/>
                                        </template>
                                    </b-form-datepicker>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="price_link mt-3 mr-0 ml-0 text-center">
                        <span class="pointer" @click="toPP">{{$t('redirect_pp')}}</span>
                    </div>
                </div>
            </b-col>
            <b-col md>
                <StatBlock class="mt-md-0 mt-3" :statistics="statistics"/>
                <div class="spacecard">
                    <b-row class="align-items-center mb-3">
                        <b-col>
                            <h2 class="mb-0">
                                {{ $t('payments') }}
                            </h2>
                        </b-col>
                        <b-col cols="auto">
                            <span class="text-muted small" v-if="paginator">{{ $t('operations') }}: {{ paginator.total }}</span>
                        </b-col>
                    </b-row>
                    <div class="search-input-wrapper">
                        <input class="search-form-control" :placeholder="$t('search')" v-model="search"/>
                        <SearchSvg class="position-absolute search-icon"/>
                    </div>
                    <PaymentTeaser v-for="(p, pind) in payments"
                                   :payment="p"
                                   :readonly="false"
                                   @refresh="getPayments"
                                   :key="'payment-'+pind"/>
                </div>
                <Paginator :paginator="paginator"/>
            </b-col>
        </b-row>
        <AddPaymentModal @refresh="getPayments"/>
    </section>
</template>

<script>
    import EventBus from '@/services/event-bus'
    import { PaymentService } from '@/services/api.service'
    import AddPaymentModal from "@/views/pages/instructor/payments/AddPaymentModal"
    import PaymentTeaser from "@/views/teasers/PaymentTeaser"
    import CalendarSvg from '@/assets/svg/calendar.svg'
    import SearchSvg from '@/assets/svg/search.svg'
    import PlusSvg from '@/assets/svg/plus.svg'
    import LangHelper from "@/services/lang.helper"
    import Paginator from "@/views/parts/general/Paginator"
    import StatBlock from '@/views/pages/instructor/payments/StatBlock'
    // import StudentSearch from '@/views/parts/class/StudentSearch'
    const moment = require('moment')

    export default {
        components: { PaymentTeaser, AddPaymentModal, CalendarSvg, SearchSvg, Paginator, StatBlock, PlusSvg },
        data() {
            return {
                payments: [],
                from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                to: moment().format('YYYY-MM-DD'),
                search: '',
                timer: null,
                paginator: null,
                page: this.$route.query.page ? this.$route.query.page : 1,
                statistics: null,
            }
        },
        mounted() {
            EventBus.$emit('breadcrumbs',[
                { to: '/payments', title: this.$t('payments') },
            ])
            this.getPayments(true)

        },
        computed: {
            lang() {
                return LangHelper.getLowcaseLanguage()
            }
        },
        watch: {
            from() {
                this.getPayments()
            },
            to() {
                this.getPayments()
            },
            search() {
                this.getPayments(false)
            },
            $route() {
                if(this.$route.query.page != this.page) {
                    this.page = this.$route.query.page
                    this.getPayments()
                }
            }
        },
        methods: {
            toPP() {
                this.$router.push({ name: 'SchoolPricePolicies' })
            },
            addPayment() {
                this.$bvModal.show('addpaymentmodal')
            },
            getPayments(immediate = true) {
                if(this.timer) {
                    clearTimeout(this.timer)
                }
                let params = {
                    from: this.from,
                    to: this.to,
                    search: this.search,
                }
                this.timer = setTimeout(() => {
                    PaymentService.get(params, this.page).then(res => {
                        this.payments = res.data.data.data
                        this.paginator = res.data.data
                    })
                }, (immediate ? 1 : 250))
                PaymentService.getStats(params).then(res => {
                    this.statistics = res.data.data
                })
            },
            destroy(id) {
                this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
                    PaymentService.delete(id).then(() => {
                        // this.$bvModal.hide('addclassmodal')
                        // this.$success(this.$t('class_removed'))
                        // this.$emit('refresh')
                    })
                })
            },
        },
        metaInfo() {
            return {
                title: this.$t('payments'),
            }
        },
    }
</script>

<style scoped lang="scss">
    .calendar-icon {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        height: 20px;
        width: 20px;
        right: 15px;
        fill: $text-gray3;
    }

    .price_link {
        span {
            font-size: 14px;
            color: #848484;

            &:hover {
                color: #43B4EB;
            }
        }
    }
</style>


