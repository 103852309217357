<template>
    <div>
        <b-row>
            <b-col v-for="(s,sind) in blocks" :md="12 / (blocks.length)" cols="6" :key="'stat-'+sind" class="mb-3">
                <div class="spacecard sm">
                    <b-row class="align-items-center">
                        <b-col cols="auto">
                            <div class="stat-icon">
                                <component :is="s.icon"/>
                            </div>
                        </b-col>
                        <b-col>
                            <div class="pl-1">
                                <div class="text-muted small">{{ $t(s.title) }}</div>
                                <div v-if="statistics && statistics[s.id]">
                                    {{ statistics[s.id] | formatNumber }} {{ $t(s.units) }}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import CoinsSvg from "@/assets/svg/coins.svg"
    import StudentSvg from "@/assets/svg/student-w.svg"
    import GroupSvg from "@/assets/svg/group.svg"
    import BookSvg from "@/assets/svg/bookvideo.svg"


    export default {
        components: {
            CoinsSvg, StudentSvg, GroupSvg, BookSvg
        },
        data() {
            return {
                blocks: [
                    { id: "total", title: "total", icon: CoinsSvg, units: this.$store.state.user.currency },
                    { id: "lessons", title: "lessons", icon: BookSvg, units: "lessons" },
                    { id: "students", title: "students", icon: StudentSvg, units: "students" },
                    { id: "groups", title: "groups", icon: GroupSvg, units: "groups" },
                ]
            }
        },
        props: {
            statistics: {}
        },
        mounted() {
            if(!this.$store.state.user.can_admin_payments) {
                this.blocks = this.blocks.filter(function( obj ) {
                    return obj.id !== "total"
                })
            }
        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">
    .stat-icon {
        background: $violet;
        border-radius: 13px;
        width: 42px;
        height: 42px;
        display: inline-block;
        text-align: center;
        line-height: 42px;
        svg {
            width: 18px;
            height: 18px;
            fill: #fff;
        }
    }
    @media(max-width: 768px) {
        .stat-icon {
            width: 36px;
            height: 36px;
            line-height: 36px;
            svg {
                width: 16px;
                height: 16px;
            }
        }
        .spacecard {
            font-size: 14px;
        }
    }
</style>

